import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import { Link } from "react-router-dom";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
 


function Register() {

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/shop");
    }
  });

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [error_list, setErrors] = useState([]);

  async function signUp() {
    // console.warn(name, email, password)
    let item = { name, email, phone, password };
    // console.warn(item)
    let result = await fetch("https://claimsapi.codekali.com/api/register", {
      method: "POST",
      body: JSON.stringify(item),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    result = await result.json();
    console.log(result.status);
    if (result.status === 200) {
      localStorage.setItem("user-info", JSON.stringify(result));
      navigate("/shop");
    } else {
      // return;
      let error_list;
      error_list = result.errors;
      setErrors(error_list);
      // console.log(error_list);
      console.log(result.errors);
    }
  }

  const [isChecked, setIsChecked] = useState(false);
  const [type, setType] = useState("password");
  const handleToggle = () => {
    setIsChecked(!isChecked);
    // alert(type);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid pt-5 mt-5">
            <div className="col-md-8 offset-md-2">
              <div className="card soko-rounded shadow-sm mb-3">
                <div className="card-body">
              <h4 className="text-center soko-bold mb-3">Sign Up</h4>
              <form id="signUpVendor" onSubmit={(e) => {e.preventDefault();}}>
              <Col className="mb-3">
                <Form.Group controlId="Name">
                  <Form.Label>Name</Form.Label>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="text"
                    placeholder="Name"
                    autoComplete="new-name"
                    className="form-control"
                  />
                  <span className="text-danger">{error_list.name}</span>
                </Form.Group>
              </Col>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Group controlId="Email">
                    <Form.Label>Email</Form.Label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      placeholder="Email"
                      autoComplete="new-email"
                      className="form-control"
                    />
                    <span className="text-danger">{error_list.email}</span>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="Phone">
                    <Form.Label>Phone</Form.Label>
                    <input
                      maxLength="10"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      type="text"
                      placeholder="Phone"
                      autoComplete="new-phone"
                      className="form-control"
                    />
                    <span className="text-danger">{error_list.phone}</span>
                  </Form.Group>
                </div>
              </div>
              <Col className="mb-3">
                <Form.Group controlId="Password">
                  <Form.Label>Password</Form.Label>
                  <input
                    type={type}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Password"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  <span className="text-danger">{error_list.password}</span>
                </Form.Group>
              </Col>

              <div className="mb-3">
              <input className="form-check-input me-2"
                type="checkbox"
                id="showPwd"
                checked={isChecked}
                onChange={handleToggle}
              />
              <label className="soko-pointer" htmlFor="showPwd"> Show Password</label>
              </div>

              <div className="col-md-6 offset-md-3">
              <button
                type="submit"
                onClick={signUp}
                className="btn btn-primary w-100 mb-3"
              > <FontAwesomeIcon icon={faUserPlus} /> Create Account
              </button>
              </div>
              </form>
            <div className="text-center mb-3">

              <Link to={"/login"}>
                 Already have an account? Login instead
              </Link>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
