import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Members.css";
import Sidebar from "./Sidebar";

import Header from "./layouts/Header";

const InsuranceCategories = () => {
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadCategories();
  }, [categories]);

  const loadCategoriess = async () => {
const data = await axios.get("https://claimsapi.codekali.com/api/insurance_categories");
setCategories(data['insurance_categories']);
  };


  const loadCategories = () =>{
return fetch("https://claimsapi.codekali.com/api/insurance_categories")
.then((response) => response.json())
.then((data) => setCategories(data['insurance_categories']))
.finally(() => {
// setIsLoading(false);
});
}

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
          <Header />
        <div className="container-fluid mt-3">
     <div className="row">
        <Sidebar />

        <div className="col-md-9">
      <div className="all-members">
        <h4>Insurance Categories</h4>
        <hr />

        <section className="search-bar">
        <div className="text-center">
        <p>
        <small>Search by name</small>
        </p>
        </div>
          <form
            className=" d-flex justify-content-center col-md-6 mx-auto my-2"
            onSubmit={handleSubmit}
          >
            <input
              className="form-control shadow-sm text-center"
              type="search"
              placeholder="Search..."
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </section>

        <section className="py-3 px-2">
        <div className="table-responsive">
          <table className="table table-striped shadow text-center">
            <thead className="table-dark">
            <tr>
                <td scope="col">Id</td>
                <td scope="col">Name</td>
                <td scopr="col">Action</td>
              </tr>
            </thead>
            <tbody>
 
              {categories.filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.name.toLowerCase().includes(search);
                })

 
               // .filter(user => user.age > 40 && user.occupation === 'programmer');

                .map((category, index) => {
                  return (
                    <tr key={index}>
                          <th scope="row">#0{category.id}</th>
                      <td>{category.name}</td>
                      <td>
                      <Link
                          to={`#`}
                          className="btn btn-primary me-2 btn-sm"
                        >
                          View
                        </Link>
                        <Link
                          to={`#`}
                          className="btn btn-warning btn-sm"
                        >
                          Update
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div>
        </section>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default InsuranceCategories;