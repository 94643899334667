import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Register from "./Register";

function Protected(props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      navigate("/login", { replace: true });
    } else {
    }
  });

  let Cmp = props.Cmp;

  return (
    <>
      {localStorage.getItem("user-info") ? (
        <>
          <div>
            <Cmp />
          </div>
        </>
      ) : (
        <>
          <Register />
        </>
      )}
    </>
  );
}

export default Protected;
