import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";

import Header from "./layouts/Header";

const Members = () => {
  const [members, setMembers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadMembers();
  }, [members]);

  const loadMemberss = async () => {
const data = await axios.get("https://claimsapi.codekali.com/api/users");
setMembers(data['users']);
  };


  const loadMembers = () =>{
return fetch("https://claimsapi.codekali.com/api/users")
.then((response) => response.json())
.then((data) => setMembers(data['users']))
.finally(() => {
// setIsLoading(false);
});
}

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
          <Header />
     <div className="container-fluid mt-5 pt-5">
       <div className="row">
        <Sidebar />

        <div className="col-md-9">
      <div className="all-members">
        <h4>Members</h4>
        <hr />

        <section className="search-bar">
        <div className="text-center">
        <p>
        <small>Filter by name, email or phone number</small>
        </p>
        </div>
          <form
            className=" d-flex justify-content-center col-md-6 mx-auto my-2"
            onSubmit={handleSubmit}
          >
            <input
              className="form-control shadow-sm text-center"
              type="search"
              placeholder="Search..."
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </section>

        <section className="py-3 px-2">
        <div className="table-responsive">
          <table className="table table-striped shadow text-center">
            <thead className="table-dark">
            <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scopr="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {members.filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.name.toLowerCase().includes(search) || item.email.toLowerCase().includes(search) || item.phone.includes(search);
                })

 
               // .filter(user => user.age > 40 && user.occupation === 'programmer');

                .map((member, index) => {
                  return (
                    <tr key={index}>
                          <td>#CLM0{member.id}</td>
                      <td>{member.name}</td>
                      <td>{member.email}</td>
                      <td>{member.phone}</td>
                      <td>
                      <Link
                          to={`#`}
                          className="btn btn-primary me-2 btn-sm"
                        >
                          View
                        </Link>
                        <Link
                          to={`#`}
                          className="btn btn-warning btn-sm"
                        >
                          Update
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div>
        </section>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Members;