import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import Header from "./layouts/Header";

export default function CreateInsuranceClaim() {
  const { id } = useParams();

  const [insurance_claim, setClaim] = useState([]);
  const [member, setMember] = useState([]);
  const [company, setCompany] = useState([]);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState([]);

  useEffect(() => {
    const fetchClaim = async () => {
      await axios
        .get(`https://claimsapi.codekali.com/api/insurance_claims/${id}`)
        .then(({ data }) => {
          setClaim(data.insurance_claim);
          setMember(data.insurance_claim.user);
          setCompany(data.insurance_claim.insurance_company);
          setType(data.insurance_claim.insurance_type);
          setCategory(data.insurance_claim.insurance_category);
        })
        .catch(({ response: { data } }) => {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        });
    };

        // console.log(insurance_claim['user']['name']);

    fetchClaim();
  }, [id]);

  return (
    <>
      <Header />
      <div className="container-fluid pt-5 mt-5">
      <div className="row">
        <Sidebar />

        <div className="col-md-9">
       
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">View Insurance Claim</h4>
                <hr />

          <table className="table table-striped shadow-sm">

                <tbody>
                  <tr>
                    <td>
                      <strong>Code</strong>
                    </td>
                    <td align="right">#{insurance_claim.code}</td>
                  </tr>

                   <tr>
                    <td>
                      <strong>Amount</strong>
                    </td>
                    <td align="right">Ksh{insurance_claim.amount}</td>
                  </tr>

                            <tr>
                    <td>
                      <strong>Email</strong>
                    </td>
                    <td align="right">{member.email}</td>
                  </tr>
                      
                         <tr>
                    <td>
                      <strong>Member</strong>
                    </td>
                    <td align="right">{member.name}</td>
                  </tr>

                         <tr>
                    <td>
                      <strong>Phone</strong>
                    </td>
                    <td align="right">{member.phone}</td>
                  </tr>
 

      <tr>
                    <td>
                      <strong>Company</strong>
                    </td>
                    <td align="right">{company.name}</td>
                  </tr>


                     <tr>
                    <td>
                      <strong>Type</strong>
                    </td>
                    <td align="right">{type.name}</td>
                  </tr>

                     <tr>
                    <td>
                      <strong>Category</strong>
                    </td>
                    <td align="right">{category.name}</td>
                  </tr>

                      <tr>
                    <td>
                      <strong>Status</strong>
                    </td>
                    <td align="right"><span className="badge badge-success bg-success">Completed</span></td>
                  </tr>
                         <tr>
                    <td>
                      <strong>Notes</strong>
                    </td>
                    <td align="right">{insurance_claim.description}</td>
                  </tr>

                     <tr>
                    <td>
                      <strong>Created At</strong>
                    </td>
                    <td align="right">{insurance_claim.created_at}</td>
                  </tr>
                </tbody>
                </table>

                <div className="text-center">

                    <button className="btn btn-primary">
                    <i className="fa-solid fa-download"></i> Download Claim
                    </button>

                </div>
              </div>
            </div>
            <div className="py-5"></div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
}
