import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import ProgressiveImage from "react-progressive-graceful-image";
import placeholder from "../images/lazyloader.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Cart from './Cart'
import { CartContext } from '../context/cart.jsx'

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function Product() {
  const { slug } = useParams();

  const [name, setName] = useState("");
  const [category_name, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState(null);

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      return fetch(`https://myduka.codekali.com/api/product/${slug}`)
        .then((response) => response.json())
        .then((data) => setProducts(data["products"]))
        .finally(() => {
          setIsLoading(false);
        });
    };



           const formatPrice = (value) =>
  new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'KES'
  }).format(value);
  
    const fetchProduct = async () => {
      await axios
        .get(`https://myduka.codekali.com/api/product/${slug}`)
        .then(({ data }) => {
          const { name, description, image, price } = data.product;

          // console.log(data);

         setProduct(data.product);


          setName(name);
          setCategory(data.category_name);
          setDescription(description);
          setImage(image);
          setPrice(formatPrice(price));
        })
        .catch(({ response: { data } }) => {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        });
    };
    fetchProduct();
    fetchData();
  }, [slug]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }

    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState([]);

  const productsCount = products.length;

  // cart

   const { cartItems, addToCart , removeFromCart} = useContext(CartContext)

   


  const notifyAddedToCart = (item) => toast.success(`${item.name} added to cart!`, {
    position: "bottom-center",
    autoClose: 5000,
   hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    theme: 'colored',
    style: {
      backgroundColor: 'green',
      color: '#fff',
    }
    });

  const notifyRemovedFromCart = (item) => toast.error(`${item.name} removed from cart!`, {
    position: "bottom-center",
    autoClose: 5000,
   hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    theme: 'colored',
    style: {
      backgroundColor: 'red',
      color: '#fff',
    }
    });

    const handleRemoveFromCart = (product) => {
      removeFromCart(product);
      notifyRemovedFromCart(product);
    };

  return (
    <>
      <Header />
        <ToastContainer />
      <div className="container-fluid">
        <div className="py-3"></div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card soko-rounded shadow-sm h-100">
              <div className="card-body">
                {isLoading ? (
                  <Skeleton className="sokomoko-single-product" />
                ) : (
                  <ProgressiveImage
                    src={
                      image
                        ? `https://myduka.codekali.com/product/image/${image}`
                        : { placeholder }
                    }
                    placeholder={placeholder}
                  >
                    {(src, loading) => (
                      <img
                        className={`image${
                          loading
                            ? " loading sokomoko-single-product w-100"
                            : " loaded sokomoko-single-product w-100"
                        }`}
                        src={src}
                        alt="home1"
                      />
                    )}
                  </ProgressiveImage>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card soko-rounded shadow-sm h-100">
              <div className="card-body">
                {isLoading ? (
                  <>
                  <div className="mb-3">
                    <Skeleton height={20} width={`30%`} />
                  </div>
                  <div className="mb-2">
                    <Skeleton height={20} width={`100%`} />
                    <Skeleton height={20} width={`80%`} />
                  </div>

                  <div className="mb-2">
                    <Skeleton height={35} width={`40%`} />
                  </div>

                   <div className="mb-2">
                    <Skeleton height={20} width={`100%`} />
                    <Skeleton height={20} width={`80%`} />
                    <Skeleton height={20} width={`60%`} />
                    <Skeleton height={20} width={`40%`} />
                    <Skeleton height={20} width={`20%`} />
                  </div>
                <div className="row mt-3">
                  <div className="col-md-6 mb-3">
                    <Skeleton height={40} width={`100%`} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Skeleton height={40} width={`100%`} />
                  </div>
                  </div>
                </>
                ) : (
                <>
                  <span className="badge badge-warning bg-warning mb-3 soko-round">
                    {category_name}
                  </span>

                <h4 className="text-dark mb-3 soko-bold">{name}</h4>
                <h5 className="text-dark soko-bold mb-3">{price}</h5>
                <p className="mb-3">{description}</p>
                <div className="row mt-5">
                  <div className="col-md-6 mb-3">
                    <Button
                      className="soko-round w-100"
                      variant="primary"
                      onClick={handleShow}
                    >
                      
                      Buy Express
                    </Button>
                  </div>

                  <div className="col-md-6">
                         {
                  !cartItems.find(item => item.id === product.id) ? (
                    <button className="btn btn-primary soko-round w-100"
                      onClick={() => {
                        addToCart(product)
                        notifyAddedToCart(product)
                      }
                      }
                      >
                        <FontAwesomeIcon className="me-1" icon={faCartPlus} /> Add to cart
                      </button>
                  ) : (
                    <div className="d-flex align-items-center gap-4">
                        <button
                      className="btn btn-secondary soko-round w-50"
                      onClick={() => {
                        const cartItem = cartItems.find((item) => item.id === product.id);
                        if (cartItem.quantity === 1) {
                          handleRemoveFromCart(product);
                        } else {
                          removeFromCart(product);
                        }
                      }}
                    >
                      -
                    </button>
                    <small className="soko-bold">{cartItems.find(item => item.id === product.id).quantity}</small>
                    <button
                      className="btn btn-warning soko-round w-50"
                      onClick={() => {
                        addToCart(product)
                      }}
                    >
                      +
                    </button>
                
                  </div>
                  )
                }
                  </div>
                </div>

                

                <Modal
                  animation={false}
                  show={show}
                  onHide={handleClose}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenterr"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h6 className="text-site soko-bold">
                        <small>Buy {name}</small>
                      </h6>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <span className="badge badge-warning bg-warning soko-round">
                      Total: Ksh. {price}
                    </span>
                    <p></p>
                    <input
                      className="form-control mb-3"
                      type="hidden"
                      name="phone"
                      value={price}
                    />
                    <input
                      className="form-control soko-round mb-3"
                      type="text"
                      name="phone"
                      placeholder="E.g 0712345678"
                    />
                    <div className="col-md-4">
                      <Button className="soko-round w-100" variant="primary">
                        Pay Now
                      </Button>
                    </div>
                    <div className="py-3"></div>
                    <small style={{ fontSize: "12px" }}>
                      * Click Pay Now and Wait for an MPESA prompt on your phone
                      then ENTER PIN to confirm...
                    </small>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      className="soko-round"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                </>
                )}

             
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3 shadow-sm soko-rounded">
          <div className="card-body">
            <Tabs
              defaultActiveKey="product-desc"
              id="soko-product-info-bar"
              className="mb-3"
              justify
            >
              <Tab
                eventKey="product-desc"
                title={
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faList} /> Description{" "}
                  </span>
                }
              >

                {isLoading ? (
<>

                   <div className="mb-2">
                    <Skeleton height={20} width={`100%`} />
                    <Skeleton height={20} width={`90%`} />
                    <Skeleton height={20} width={`80%`} />
                    <Skeleton height={20} width={`60%`} />
                    <Skeleton height={20} width={`40%`} />
                  </div>
</>
) : (
<>
<p>
                {description ? description : `New Product`}{" "}
  
</p>
</>
)}
              </Tab>

              <Tab
                eventKey="product-specs"
                title={
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faFile} /> Specifications{" "}
                  </span>
                }
              >
                {description ? description : `New Product`}{" "}
              </Tab>

              <Tab
                eventKey="product-reviews"
                title={
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faComments} /> Reviews{" "}
                  </span>
                }
              >
                product reviews
              </Tab>
            </Tabs>
          </div>
        </div>

        {productsCount > 0 ? (
          <div className="related-products-container">
            <h4 className="soko-bold text-center">Related Products</h4>
            <div className="row g-1">
              {!isLoading
                ? products &&
                  products.length > 0 &&
                  products.map((productsObj) => (
                    <div key={productsObj.id} className="col-md-3 col-6 mb-3">
                      <Link to={`/product/${productsObj.slug}`}>
                        <div className="card soko-rounded shadow-sm h-100">
                          <div className="card-body">
                            <ProgressiveImage
                              src={
                                productsObj.image
                                  ? `https://myduka.codekali.com/product/image/${productsObj.image}`
                                  : `https://myduka.codekali.com/images/avatar.png`
                              }
                              placeholder={placeholder}
                            >
                              {(src, loading) => (
                                <img
                                  className={`image${
                                    loading
                                      ? " loading product-img mb-2 w-100"
                                      : " loaded product-img mb-2 w-100"
                                  }`}
                                  src={src}
                                  alt="home1"
                                />
                              )}
                            </ProgressiveImage>

                            <h6 className="mb-2 soko-mobile-font">
                              {capFirst(productsObj.name)}
                            </h6>
                            <h6 className="soko-bold">
                              KES. {productsObj.price}
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>

                  ))
                : Array(50)
                    .fill()
                    .map((item, index) => (
                      <div className="col-md-3 mb-3" key={index}>
                        <div className="card soko-product-card soko-rounded shadow-sm">
                          <div className="card-body">
                            <div
                              style={{
                                height: "200px",
                                marginBottom: ".5rem",
                              }}
                            >
                              <Skeleton height={200} width={`100%`} />
                            </div>
                            <h6 className="card-title">
                              <Skeleton height={25} width={`80%`} />
                            </h6>
                            <p className="card-channel mb-0">
                              <Skeleton width={`60%`} />
                            </p>
                            <p className="card-channel">
                              <Skeleton width={`60%`} />
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
            </div>
          </div>
        ) : (
          <h4 className="text-center soko-bold">No Related Products</h4>
        )}

      </div>
      <Footer />
    </>
  );
}

export default Product;
