import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logoimg from "../images/APA.jpg";

const Sidebar = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


    const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user-info"));

  // console.log(user['user']['name'])

  function logOut() {
    localStorage.clear();
    navigate("/login");
  }

  return (

        <div className="col-md-3">
      <aside id="app_side_menu" className="shadow-sm">
      
<div className="text-center mb-3">
<h6>Claims Portal</h6>

      <img width="108" height="74.4" src={logoimg}/>
</div>
      
              <ul className="app-side-menu">

    <li className="mb-3" key="Home">
        <Link to={`/`}>
        <i class="fa-solid fa-home"></i> Home  <i className="fa-solid fa-angle-right float-end me-3"></i>
        </Link>

    </li>
 

          <li className="mb-3">
              <Link to={`/dashboard`}><i class="fa-solid fa-chart-line"></i> Dashboard <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>


                {localStorage.getItem("user-info") ? (
                  <>
                 

            <li className="mb-3">
              <Link to={`/create/insurance/company`}><i class="fa-solid fa-building"></i> Create Company <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

          <li className="mb-3">
              <Link to={`/insurance/companies`}><i class="fa-solid fa-list"></i> All Companies <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

            <li className="mb-3">
              <Link to={`/create/insurance/category`}><i class="fa-solid fa-plus-circle"></i> Create Category <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

          <li className="mb-3">
              <Link to={`/insurance/categories`}><i class="fa-solid fa-list"></i> All Categories <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

            <li className="mb-3">
              <Link to={`/create/insurance/type`}><i class="fa-solid fa-plus"></i> Create Type <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

          <li className="mb-3">
              <Link to={`/insurance/types`}><i class="fa-solid fa-list"></i> All Types <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>


           <li className="mb-3">
              <Link to={`/create/insurance/claim`}><i class="fa-solid fa-plus-circle"></i> Create Claim <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

                    <li className="mb-3">
              <Link to={`/insurance/claims`}> <i class="fa-solid fa-list"></i> All Claims <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

          <li className="mb-3">
              <Link to={`/create/member`}><i class="fa-solid fa-user-plus"></i> Add Member <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
          </li>

          <li className="mb-3">
              <Link to={`/insurance/members`}><i class="fa-solid fa-users"></i> All Members <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
            
          </li>

              <li className="mb-3">

          <Link
                                title={
                                  user && user.user.name
                                    ? user.user.name
                                    : "app User"
                                }
                                onClick={logOut}
                              >
                                <FontAwesomeIcon icon={faSignOut} /> Sign out</Link>
                              </li>

                  </>
                ) : (
                  <>
                 <li className="mb-3">
              <Link to={`/login`}><i class="fa-solid fa-sign-in"></i> Login <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
            
          </li>

               <li className="mb-3">
              <Link to={`/register`}><i class="fa-solid fa-sign-in"></i> Sign Up <i className="fa-solid fa-angle-right float-end me-3"></i></Link>
            
          </li>
 
                  </>
                )}


    </ul>

<hr/>

<div className="text-center mb-5">
<p className="app-bold">Insurance Claims Ltd</p>
<p><small>2024 - All Rights Reserved</small></p>
</div>
<hr/>
<div className="py-5">
    </div>

    </aside>
    </div>



  );
};

export default Sidebar;
