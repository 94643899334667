import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./Sidebar";

import Header from "./layouts/Header";

export default function CreateInsuranceClaim() {
  const navigate = useNavigate();

  const [isClearable, setIsClearable] = useState(true);

  const [isSearchable, setIsSearchable] = useState(true);

  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [validationError, setValidationError] = useState({});

  const [user_id, setMember] = useState("");
  const [claim_date, setClaimDate] = useState("");
  const [insurance_company_id, setCompany] = useState("");
  const [insurance_type_id, setType] = useState("");
  const [insurance_category_id, setCategory] = useState("");

  const [members, setMembers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [itypes, setTypes] = useState([]);

  console.log(claim_date);

  const changeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const createClaim = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("amount", amount);
    formData.append("user_id", user_id);
    formData.append("claim_date", claim_date);
    formData.append("insurance_company_id", insurance_company_id);
    formData.append("insurance_type_id", insurance_type_id);
    formData.append("insurance_category_id", insurance_category_id);
    formData.append("description", description);
    formData.append("image", image);
 
    await axios
      .post(`https://claimsapi.codekali.com/api/insurance_claims`, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        navigate("/insurance/claims");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };



// members

  const memberOptions = [];

  // console.log(members);

  members.forEach((member, index) => {
    memberOptions.push({
      value: member.id,
      label: member.name + " - " + member.phone,
    });
  });

  const handleMemberChange = (event, value) => {
    if (!event) {
      setMember("");
    } else {
      setMember(event.value);
    }
  };

  const fetchMembers = () => {
    return fetch("https://claimsapi.codekali.com/api/users")
      .then((response) => response.json())
      .then((data) => setMembers(data["users"]));
  };

  // companies

    const companyOptions = [];

  // console.log(companies);

  companies.forEach((company, index) => {
    companyOptions.push({
      value: company.id,
      label: company.name,
    });
  });

  const handleCompanyChange = (event, value) => {
    if (!event) {
      setCompany("");
    } else {
      setCompany(event.value);
    }
  };

    const fetchCompanies = () => {
    return fetch("https://claimsapi.codekali.com/api/insurance_companies")
      .then((response) => response.json())
      .then((data) => setCompanies(data["insurance_companies"]));
  };

  // itypes

    const typeOptions = [];

  // console.log(itypes);

  itypes.forEach((itype, index) => {
    typeOptions.push({
      value: itype.id,
      label: itype.name,
    });
  });

  const handleTypeChange = (event, value) => {
    if (!event) {
      setType("");
    } else {
      setType(event.value);
    }
  };

    const fetchTypes = () => {
    return fetch("https://claimsapi.codekali.com/api/insurance_types")
      .then((response) => response.json())
      .then((data) => setTypes(data["insurance_types"]));
  };

  // cats

    const catOptions = [];

  // console.log(categories);

  categories.forEach((cat, index) => {
    catOptions.push({
      value: cat.id,
      label: cat.name,
    });
  });

  const handleCatChange = (event, value) => {
    if (!event) {
      setCategory("");
    } else {
      setCategory(event.value);
    }
  };

    const fetchCategories = () => {
    return fetch("https://claimsapi.codekali.com/api/insurance_categories")
      .then((response) => response.json())
      .then((data) => setCategories(data["insurance_categories"]));
  };

  useEffect(() => {
    fetchMembers();
    fetchCompanies();
    fetchTypes();
    fetchCategories();
  }, []);

  return (
    <>
          <Header />
    <div className="container-fluid mt-5 pt-5">
       <div className="row">
        <Sidebar />

        <div className="col-md-9">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Create Insurance Claim</h4>
                <hr />
                <div className="form-wrapper">
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {Object.entries(validationError).map(
                              ([key, value]) => (
                                <li key={key}>{value}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <Form onSubmit={createClaim}>
                    <Row className="mb-3">
                      <Form.Label>Select Member </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={memberOptions[0]}
                        isClearable={isClearable}
                        name="user_id"
                        isSearchable={isSearchable}
                        options={memberOptions}
                        onChange={handleMemberChange}
                      />
                    </Row>

                        <Row className="mb-3">
                      <Form.Label>Select Company </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={companyOptions[0]}
                        isClearable={isClearable}
                        name="insurance_company_id"
                        isSearchable={isSearchable}
                        options={companyOptions}
                        onChange={handleCompanyChange}
                      />
                    </Row>


                        <Row className="mb-3">
                      <Form.Label>Select Type </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={typeOptions[0]}
                        isClearable={isClearable}
                        name="insurance_type_id"
                        isSearchable={isSearchable}
                        options={typeOptions}
                        onChange={handleTypeChange}
                      />
                    </Row>

                            <Row className="mb-3">
                      <Form.Label>Select Category </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={catOptions[0]}
                        isClearable={isClearable}
                        name="insurance_category_id"
                        isSearchable={isSearchable}
                        options={catOptions}
                        onChange={handleCatChange}
                      />
                    </Row>

                         <Row>
                      <Col>
                        <Form.Group controlId="ClaimDate">
                          <Form.Label>ClaimDate</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            value={claim_date}
                            onChange={(event) => {
                              setClaimDate(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="Amount">
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            value={amount}
                            onChange={(event) => {
                              setAmount(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="my-3">
                      <Col>
                        <Form.Group controlId="Description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={description}
                            onChange={(event) => {
                              setDescription(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="Image" className="mb-3">
                          <Form.Label>Image</Form.Label>
                          <Form.Control
                            accept="image/*"
                            type="file"
                            onChange={changeHandler}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      variant="primary"
                      className="mt-2"
                      size="lg"
                      block="block"
                      type="submit"
                    >
                      Save Claim
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="py-5"></div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
}
