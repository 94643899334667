import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./layouts/Header";

import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Doughnut } from "react-chartjs-2";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);



const Dashboard = () => {
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalClaims, setTotalClaims] = useState(0);
  const [totalPendingClaims, setTotalPendingClaims] = useState(0);
  const [totalApprovedClaims, setTotalApprovedClaims] = useState(0);
  let user = JSON.parse(localStorage.getItem("user-info"));
 
    let myDate = new Date();
    let hours= myDate.getHours();
    let greet;
    let icon;

    if (hours < 12){
        greet =  "Morning";
        icon =  "fa-solid fa-coffee";
    }else if (hours >= 12 && hours <= 17){
        greet = "Afternoon";
        icon =  "fa-solid fa-sun";
    }else if (hours >= 17 && hours <= 24){
       greet = "Evening";
        icon =  "fa-solid fa-moon";
    }

  const [ChartData, setChartData] = useState([]);
  const [ClaimsData, setClaimsData] = useState([]);



  const loadChartData = () =>{
  return fetch("https://claimsapi.codekali.com/api/insurance_claims")
  .then((response) => response.json())
  .then((data) => setChartData(data['insurance_claims']));
  }

  const loadClaimsData = () =>{
return fetch("https://claimsapi.codekali.com/api/top_claims")
.then((response) => response.json())
.then((data) => setClaimsData(data['top_claims'])); 
}  

 

    const coptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return "Amount:" + data.formattedValue;
          },
        },
      },
      datalabels: {
        formatter: function (value) {
           //custom money icon
          return "KES" + new Intl.NumberFormat("tr-TR").format(value);
        },
        color: "white",
        font: {
          weight: 'bold',
          size:14,
          family: 'poppins'
        },
      },
    },
}

  const backgroundColors = ["#53D9D9", "#002B49", "#0067A0", "#FD8D14","#1D2B53","#5F0F40","#711DB0","#B0171F","#FF0060", "#5F0F40" ,"#FF34B3","#D04848","#0079FF","#219C90","#860A35","#AF2655","#0174BE","#393E46","#2CD3E1","#3652AD"];
  const backgroundColors2 = ["#5F0F40","#393E46","#2CD3E1","#711DB0","#B0171F","#FF0060", "#5F0F40" ,"#FF34B3","#D04848","#0079FF","#219C90","#860A35","#AF2655","#0174BE","#53D9D9", "#002B49", "#0067A0", "#FD8D14","#1D2B53","#3652AD"];
  
  const cdata = {
    labels: ChartData.map((item) => "#" +  item.code),
    datasets: [
      {
        label: ChartData.map((item) => item.code),
        data: ChartData.map((item) => item.amount),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  // top claims

    const claimsdata = {
    labels: ClaimsData.map((item) => "#" +  item.code),
    datasets: [
      {
        label: ClaimsData.map((item) => item.code),
        data: ClaimsData.map((item) => item.amount),
        backgroundColor: backgroundColors2,
        borderWidth: 1,
      },
    ],
  };


  

      useEffect(() => {
    loadChartData();
    loadClaimsData();
  }, []);

  return (
    <>
      <Header />
    <div className="container-fluid pt-5 mt-5">
      <div className="row">
        <Sidebar />

        <div className="col-md-9">
        <h4 className="mb-3"><i className={icon}></i> Good {greet}, {
                                  user && user.user.name
                                    ? user.user.name
                                    : "User"
                                }</h4>
          <div className="row mb-5 text-center">
            <div className="col-md-3 col-6 mb-3">
             <Link to={`/insurance/members`}>
              <div className="card h-100 bg-danger">
                <div className="card-body">
                  <h6 className="text-white">Total Members</h6>
                  <h5 className="text-white">{totalMembers}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3 col-6 mb-3">
             <Link to={`/insurance/claims`}>
              <div className="card h-100 bg-warning">
                <div className="card-body">
                  <h6 className="text-white">Total Claims</h6>
                  <h5 className="text-white">{totalClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3 col-6 mb-3">
             <Link to={`/insurance/claims`}>
              <div className="card h-100 bg-purple">
                <div className="card-body">
                  <h6 className="text-white">Pending Claims</h6>
                  <h5 className="text-white">{totalPendingClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3 col-6 mb-3">
             <Link to={`/insurance/claims`}>
              <div className="card h-100 bg-navy">
                <div className="card-body">
                  <h6 className="text-white">Approved Claims</h6>
                  <h5 className="text-white">{totalApprovedClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

               <div className="col-md-3 col-6 mb-3">
             <Link to={`/insurance/categories`}>
              <div className="card h-100 bg-info">
                <div className="card-body">
                  <h6 className="text-white">Insurance Categories</h6>
                  <h5 className="text-white">{totalApprovedClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>


     <div className="col-md-3 col-6 mb-3">
             <Link to={`/insurance/companies`}>
              <div className="card h-100 bg-primary">
                <div className="card-body">
                  <h6 className="text-white">Insurance Companies</h6>
                  <h5 className="text-white">{totalApprovedClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>
          </div>


          <div className="row mb-3">
          <div className="col-md-8 mb-3">
          <h6 className="text-center">Recent Claims</h6>
<Bar options={coptions} data={cdata} />


          </div>

            <div className="col-md-4">
          <h6 className="text-center">Top Claims</h6>

<Doughnut options={coptions} data={claimsdata} />

          </div>
          </div>

 
 
        </div>
      </div>
    </div>
    </>
  );
};

export default Dashboard;
