import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";

import Header from "./layouts/Header";

const InsuranceClaims = () => {
  const [claims, setClaims] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadClaims();
  }, [claims]);

  const loadClaimss = async () => {
const data = await axios.get("https://claimsapi.codekali.com/api/insurance_claims");
setClaims(data['insurance_claims']);
  };


  const loadClaims = () =>{
return fetch("https://claimsapi.codekali.com/api/insurance_claims")
.then((response) => response.json())
.then((data) => setClaims(data['insurance_claims']))
.finally(() => {
// setIsLoading(false);
});
}

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // console.log(claims);

  return (
    <>
          <Header />
     <div className="container-fluid mt-5 pt-5">
       <div className="row">
        <Sidebar />

        <div className="col-md-9">
      <div className="all-members">
        <h4>Insurance Claims</h4>
        <hr />

        <section className="search-bar">
        <div className="text-center">
        <p>
        <small>Search by name, member name, member phone, claim code, insurance category/type/company</small>
        </p>
        </div>
          <form
            className=" d-flex justify-content-center col-md-6 mx-auto my-2"
            onSubmit={handleSubmit}
          >
            <input
              className="form-control shadow-sm text-center"
              type="search"
              placeholder="Search..."
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </section>

        <section className="py-3 px-2">
        <div className="table-responsive">
          <table className="table table-striped shadow text-center">
            <thead className="table-dark">
            <tr>
                <td scope="col">Code</td>
                <td scope="col">Member</td>
                <td scope="col">Category</td>
                <td scope="col">Company</td>
                <td scope="col">Date</td>
                <td scopr="col">Action</td>
              </tr>
            </thead>
            <tbody>
              {claims.filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.code.includes(search) || item.user.name.toLowerCase().includes(search) || item.user.phone.toLowerCase().includes(search) || item.insurance_company.name.toLowerCase().includes(search) || item.insurance_category.name.toLowerCase().includes(search);
                })

 
               // .filter(user => user.age > 40 && user.occupation === 'programmer');

                .map((claim, index) => {
                  return (
                    <tr key={index}>
                          <th scope="row">#{claim.code}</th>
                      <td>{claim.user.name} - {claim.user.phone}</td>
                      <td>{claim.insurance_category.name}</td>
                      <td>{claim.insurance_company.name}</td>
                      <td>{claim.claim_date}</td>
                      <td>
                      <Link
                          to={`/insurance/claim/${claim.id}`}
                          className="btn btn-primary w-100 mb-2 btn-sm"
                        >
                          View
                        </Link>
                        <Link
                          to={`#`}
                          className="btn btn-warning w-100 btn-sm"
                        >
                          Update
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div>
        </section>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default InsuranceClaims;