import React from "react";
import { useState } from "react";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import SiteLogo from "../../images/logo2.png";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logoimg from "../../images/APA.jpg";

const Header = () => {
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user-info"));

  // console.log(user['user']['name'])

  function logOut() {
    localStorage.clear();
    navigate("/login");
  }

 const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
 
      <header className="fixed-top px-0 bg-white shadow-sm">
        <div className="border-bottom">
          <div className="container-fluid">
            <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-between">
              <Link
                to={"/"}
                className="d-flex align-items-center my-lg-0 text-dark text-decoration-none"
              >
                <img
                  src="https://sokodirectory.com/wp-content/uploads/2015/04/APA-Insurance1.jpg"
                  alt="app Header Logo"
                  width="140.4"
                  height="51.2"
                />
              </Link>

              <ul className="nav app-app-header col-lg-auto py-3 me-lg-3 justify-content-center my-md-0 text-small">
                <li>
                  <Link to={"/"} className="nav-link d-none">
                    <img
                      src={SiteLogo}
                      alt="app Nav Logo"
                      className="app-nav-logo"
                    />
                  </Link>
                </li>
 
                {localStorage.getItem("user-info") ? (
                  <>
                    <li className="me-3">
                      <Dropdown className="text-end">
                        <Dropdown.Toggle
                          variant="transparent"
                          className="text-dark"
                          id="dropdown-basic"
                        >
                          <FontAwesomeIcon icon={faUserCircle} /> Account
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={"/dashboard"}
                            className="std-font text-dark mb-2"
                          >
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={"/"}
                            className="std-font text-dark mb-2"
                          >
                            My Profile
                          </Dropdown.Item>

                          {localStorage.getItem("user-info") ? (
                            <>
                              <Dropdown.Item
                                className="std-font mb-2"
                                title={
                                  user && user.user.name
                                    ? user.user.name
                                    : "app User"
                                }
                                onClick={logOut}
                              >
                                <FontAwesomeIcon icon={faSignOut} /> Sign out
                              </Dropdown.Item>
                            </>
                          ) : (
                            <></>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                  </>
                ) : (
                  <>
                    <li>
                      <Dropdown className="text-end">
                        <Dropdown.Toggle
                          variant="transparent"
                          className="text-dark app-mobile-font"
                          id="dropdown-auth"
                        >
                          <FontAwesomeIcon icon={faUserCircle} /> Account
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={"/register"}
                            className="std-font text-dark mb-2"
                          >
                            Register
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={"/login"}
                            className="std-font text-dark mb-2"
                          >
                            Login
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
 
                  </>
                )}

                <li>

                      <button onClick={handleShow} className="me-2 btn btn-primary">
            <i className="fa fa-bars"></i>
          </button>
                </li>
 
              </ul>
            </div>
          </div>
        </div>
      </header>


          <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Claims Portal</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
<div className="text-center mb-3">

      <img width="108" alt="LogoSide" height="74.4" src={logoimg}/>
</div>
          <ul className="app-side-menu px-0">
          
            <li className="mb-3">
              <Link onClick={handleClose} className="text-dark" to={`/`}>
                <i class="fa-solid fa-home"></i> Home <i className="fa-solid fa-angle-right float-end"></i>
              </Link>
            </li>
     

          <li className="mb-3">
              <Link onClick={handleClose} to={`/dashboard`}><i class="fa-solid fa-chart-line"></i> Dashboard <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>



                {localStorage.getItem("user-info") ? (
                  <>
                 
            <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/company`}><i class="fa-solid fa-building"></i> Create Company <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/companies`}><i class="fa-solid fa-list"></i> All Companies <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

            <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/category`}><i class="fa-solid fa-plus-circle"></i> Create Category <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/categories`}><i class="fa-solid fa-list"></i> All Categories <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

            <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/type`}><i class="fa-solid fa-plus"></i> Create Type <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/types`}><i class="fa-solid fa-list"></i> All Types <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>


           <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/claim`}><i class="fa-solid fa-plus-circle"></i> Create Claim <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

                    <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/claims`}> <i class="fa-solid fa-list"></i> All Claims <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/create/member`}><i class="fa-solid fa-user-plus"></i> Add Member <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/members`}><i class="fa-solid fa-users"></i> All Members<i className="fa-solid fa-angle-right float-end"></i></Link>
            
          </li>
       <li className="mb-3">

          <Link
                                title={
                                  user && user.user.name
                                    ? user.user.name
                                    : "app User"
                                }
                                onClick={logOut}
                              >
                                <FontAwesomeIcon icon={faSignOut} /> Logout</Link>
                              </li>
                  </>
                ) : (
                  <>
                
 <li className="mb-3">
              <Link onClick={handleClose} to={`/login`}><i class="fa-solid fa-sign-in"></i> Login <i className="fa-solid fa-angle-right float-end"></i></Link>
            
          </li>


           <li className="mb-3">
              <Link onClick={handleClose} to={`/register`}><i class="fa-solid fa-user-plus"></i> Create Account <i className="fa-solid fa-angle-right float-end"></i></Link>
            
          </li>

 
                  </>
                )}


  
          </ul>
<hr/>
          <div className="text-center mb-5">
<p className="app-bold">Insurance Claims Ltd</p>
<p><small>2024 - All Rights Reserved</small></p>
</div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
